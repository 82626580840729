import React, { useEffect } from "react";
import lottie from "lottie-web";
import data from "../../assets/lottie/Loader.json";
import logo from "../../assets/svg/logo.svg";
function Loader() {
  useEffect(() => {
    const animationContainer = document.getElementById("react-logo");
    let playCount = 0;
    const maxPlays = 2;

    const animation = lottie.loadAnimation({
      container: animationContainer,
      animationData: data,
      loop: false,
      autoplay: true,
    });

    animation.addEventListener("complete", () => {
      playCount += 1;
      if (playCount < maxPlays) {
        animation.goToAndPlay(0);
      }
    });

    return () => {
      animation.removeEventListener("complete", () => {
        playCount += 1;
        if (playCount < maxPlays) {
          animation.goToAndPlay(0);
        }
      });
      animation.destroy();
    };
  }, []);

  return (
    <div className="lottie-loader d-flex">
      {/* <div id="react-logo" /> */}
      <img
        src={logo}
        height="200"
        className="d-flex justify-content-center align-items-center"
      />
    </div>
  );
}

export default Loader;
